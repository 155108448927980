@use "sass:math";

$total: 200;
$time: 10s;

.montauk-container {
	overflow: hidden;
}

.montauk-wrap {
	height: 100%;
	transform-style: preserve-3d;
	perspective: 800px;

}

.montauk-triangle {
	height: 0;
	width: 0;
	position: absolute;
	top: 50%;
	left: 50%;
}

@for $i from 1 through $total {
	$size: random(50) * 1px;
	$rotate: random(360) * 1deg;

	.montauk-triangle:nth-child(#{$i}) {
		border-top: $size solid hsla(random(360), 100%, 50%, 1);
		border-right: $size solid transparent;
		border-left: $size solid transparent;
		margin-left: -$size*0.5;
		margin-top: -$size*0.5;
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
		transform: rotate($rotate) translate3d(0, 0, -1500px) scale(0);
		animation: anim#{$i} $time infinite linear;
		animation-delay: $i * -(math.div($time, $total));
		opacity: 0;
	}

	@keyframes anim#{$i} {
		0% {
			opacity: 1;
			transform: rotate($rotate * 1.5) translate3d(random(1000) * 1px, random(1000) * 1px, 1000px) scale(1);
		}
	}
}